'use client';

import React from 'react';
import Anchor from 'libs/global/components/Anchor';
import queryString from 'query-string';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from '../hooks';

const AdminWarning = () => {
    const user = useAppSelector((state) => state.user);
    const logged_in_as = useAppSelector((state) => state.logged_in_as);

    if (logged_in_as && user && user.id !== logged_in_as.id) {
        return (
            <Box
                position="fixed"
                bottom={-1}
                rounded="md"
                left="50%"
                bg="red.300A"
                color="white"
                fontSize="sm"
                transform="translateX(-50%)"
                zIndex={100}
                py={2}
                px={4}>
                Viewing as {user.email}.{' '}
                <Anchor
                    onClick={() => {
                        const query = queryString.parse(window.location.search);
                        delete query.as;
                        window.location.search = queryString.stringify(query);
                    }}>
                    Return to {logged_in_as.email}
                </Anchor>
            </Box>
        );
    } else {
        return null;
    }
};

export default AdminWarning;
